<template>
  <crud-details-page
    api="events/rush-event-reward"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="4">
        <!-- SeasonNo -->
        <crud-field-number
          api-field-name="SeasonNo"
          :min="0"
          :max="65535"
        />
        <!-- No -->
        <crud-field-number
          api-field-name="No"
          :max="255"
          required
        />
        <!-- SeasonPassPoints -->
        <crud-field-number
          api-field-name="SeasonPassPoints"
        />
      </fields-col>
      <fields-col :sm="4">
        <!-- XpPct -->
        <crud-field-number
          api-field-name="XpPct"
          :max="255"
        />
        <!-- Energy -->
        <crud-field-number api-field-name="Energy" />
        <!-- Gems -->
        <crud-field-number api-field-name="Gems" />
      </fields-col>
      <fields-col :sm="12">
        <template
          v-for="i in 4"
          :key="i"
        >
          <fields-col :sm="16">
            <crud-field-selector-item :api-field-name="`Item${i}`" />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`Item${i}Amount`"
              :max="255"
            />
          </fields-col>
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
