<script>
export default {
  data() {
    return {
      loading: {},
      weights: ['WeightCommon', 'WeightUncommon', 'WeightRare', 'WeightEpic', 'WeightLegendary', 'WeightCommonExtra', 'WeightUncommonExtra', 'WeightRareExtra', 'WeightEpicExtra', 'WeightLegendaryExtra']
    }
  },
  computed: {
    atLeast1Chance() {
      return (form, weight) => {
        const chance =
            100 *
            (1 -
              (1 -
                weight /
                (form.WeightCommon + form.WeightUncommon + form.WeightRare + form.WeightEpic + form.WeightLegendary + form.WeightCommonExtra + form.WeightUncommonExtra + form.WeightRareExtra + form.WeightEpicExtra + form.WeightLegendaryExtra)) ** form.Quantity)
        return chance.toFixed(2)
      }
    },
    weightLabel() {
      return (weight) => {
        const split = weight.split(/(?=[A-Z])/)

        return `${split.length === 2 ? `Weight ${split[1]} Golden` : `Weight ${split[1]} Diamond`}`
      }
    },
    weightChanceLabel() {
      return (weight) => {
        const label = this.weightLabel(weight)

        return `At Least 1 ${label} Chance (in %)`
      }
    }
  },
  methods: {
    initItem(form) {
      form.simChestLevel = 5
      form.simEventId = 1
      form.simChestGroup = 0
      form.simSeasonDay = 1
      form.simChestIncomeCount = 100
      form.simChestIncomeSimulations = 25
      return form
    },
    simChest(form) {
      this.loading.simChest = true
      const envApi = this.$store.getters['auth/envs']?.dev
      this.$axios
        .get(`${envApi}/proxy/simchest/`, {
          params: {
            chest_id: form.Versioned.Root,
            event_id: form.simEventId,
            level: form.simChestLevel,
            group: form.simChestGroup,
            season_day: form.simSeasonDay,
            count: form.simChestIncomeCount,
            simulations: form.simChestIncomeSimulations
          }
        })
        .then(({ data }) => {
          this.$messageBox
            .confirm(data.logs, undefined, {
              confirmButtonText: 'OK',
              type: 'info',
              dangerouslyUseHTMLString: true,
              showCancelButton: false,
              icon: 'none',
              customClass: 'md:max-w-screen-md'
            })
            .catch(() => {})
        })
        .catch(this.$utils.catchError)
        .then(() => {
          this.loading.simChest = false
        })
    },
    simChestIncome(form) {
      this.loading.simChestIncome = true
      const envApi = this.$store.getters['auth/envs']?.dev
      this.$axios
        .get(`${envApi}/proxy/simchestincome/`, {
          params: {
            chest_id: form.Versioned.Root,
            event_id: form.simEventId,
            level: form.simChestLevel,
            group: form.simChestGroup,
            season_day: form.simSeasonDay,
            count: form.simChestIncomeCount,
            simulations: form.simChestIncomeSimulations
          }
        })
        .then(({ data }) => {
          this.$messageBox
            .confirm(data.logs, undefined, {
              confirmButtonText: 'OK',
              type: 'info',
              dangerouslyUseHTMLString: true,
              showCancelButton: false,
              icon: 'none',
              customClass: 'md:max-w-screen-md'
            })
            .catch(() => {})
        })
        .catch(this.$utils.catchError)
        .then(() => {
          this.loading.simChestIncome = false
        })
    }
  }
}
</script>

<template>
  <crud-details-page
    api="chests/chest"
    disable-top-margin
    :render-init-item="initItem"
  >
    <template #form="{ form }">
      <fields-col :sm="6">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.ChestType"
          :visible-options="['EventCardPack']"
          required
        />
        <!-- NameTag -->
        <crud-field-selector-client-strings api-field-name="NameTag" />
        <!-- Quantity -->
        <crud-field-number api-field-name="Quantity" />
        <!-- RequiredLevel -->
        <crud-field-number api-field-name="RequiredLevel" />
      </fields-col>
      <fields-col :sm="4">
        <!-- Price1 -->
        <crud-field-number api-field-name="Price1" />
        <!-- PriceSpecial -->
        <crud-field-number api-field-name="PriceSpecial" />
        <!-- PriceExchange -->
        <crud-field-number api-field-name="PriceExchange" />
        <!-- PriceExchangeReset -->
        <crud-field-number api-field-name="PriceExchangeReset" />
        <!-- ExchangeCooldownHours -->
        <crud-field-number api-field-name="ExchangeCooldownHours" />
      </fields-col>
      <fields-col :sm="2">
        <!-- IsEventChest -->
        <crud-field-switcher
          label="Only by key"
          api-field-name="IsEventChest"
        />
        <!-- IsAutoOpen -->
        <crud-field-switcher api-field-name="IsAutoOpen" />
        <!-- IsGoldenPack -->
        <crud-field-switcher api-field-name="IsGoldenPack" />
        <!-- IsGoldenPackNew -->
        <crud-field-switcher api-field-name="IsGoldenPackNew" />
      </fields-col>
      <fields-col :sm="6">
        <!-- AssetShopPreview -->
        <crud-field-selector-files api-field-name="AssetShopPreview" />
        <!-- AssetChestAnimation -->
        <crud-field-selector-files api-field-name="AssetChestAnimation" />
        <!-- AssetBackground -->
        <crud-field-selector-files api-field-name="AssetBackground" />
        <!-- AssetBackground2 -->
        <crud-field-selector-files api-field-name="AssetBackground2" />
      </fields-col>
      <fields-col :sm="6">
        <!-- AssetSeasonPreview -->
        <crud-field-selector-files api-field-name="AssetSeasonPreview" />
        <!-- AssetChestPreview -->
        <crud-field-selector-files api-field-name="AssetChestPreview" />
        <!-- AssetSeasonBanner -->
        <crud-field-selector-files api-field-name="AssetSeasonBanner" />
      </fields-col>
      <section-divider>Loot settings</section-divider>
      <fields-col
        :sm="16"
      >
        <template
          v-for="weight in weights"
          :key="weight"
        >
          <!-- WeightCommon -->
          <crud-field-number
            :sm="12"
            :label="weightLabel(weight)"
            :api-field-name="weight"
          />
          <crud-field-slot
            :sm="12"
            :label="weightChanceLabel(weight)"
          >
            {{ atLeast1Chance(form, form[weight]) }}
          </crud-field-slot>
        </template>
      </fields-col>
      <section-divider>Loots</section-divider>
      <el-card
        shadow="never"
        class="gs-font-scaled relative mt-6 mb-10"
      >
        <fields-col>
          <crud-field-slot
            :sm="4"
            label="Sim Pack"
            empty-label
          >
            <el-button
              class="gs-height-related-xl gs-btn-outlined-primary-neutral gs-loading gs-no-icon"
              :loading="loading.simChest"
              @click="simChest(form)"
            >
              Sim pack
            </el-button>
          </crud-field-slot>
          <crud-field-slot
            :sm="20"
            label="Sim Chest Income"
            empty-label
          >
            <el-button
              class="gs-height-related-xl gs-btn-outlined-primary-neutral gs-loading gs-no-icon"
              :loading="loading.simChestIncome"
              @click="simChestIncome(form)"
            >
              Sim pack income
            </el-button>
          </crud-field-slot>
          <crud-field-number
            :sm="4"
            api-field-name="simChestLevel"
            :max="1000"
            label="Sim level"
            vertical
          >
            <template #fieldAppend>
              <div class="font-related-xs text-neutral-500">
                (cards have required level to drop)
              </div>
            </template>
          </crud-field-number>
          <crud-field-number
            :sm="4"
            api-field-name="simEventId"
            :min="1"
            label="Sim EventId"
            vertical
          >
            <template #fieldAppend>
              <div class="font-related-xs text-neutral-500">
                (correct event id)
              </div>
            </template>
          </crud-field-number>
          <crud-field-number
            :sm="4"
            api-field-name="simChestGroup"
            :max="2"
            label="Sim group"
            vertical
          >
            <template #fieldAppend>
              <div class="font-related-xs text-neutral-500">
                (groups 0,1,2)
              </div>
            </template>
          </crud-field-number>
          <crud-field-number
            :sm="4"
            api-field-name="simSeasonDay"
            :max="30"
            label="Sim season day"
          />
          <crud-field-number
            :sm="4"
            api-field-name="simChestIncomeCount"
            label="Number of packs"
          />
          <crud-field-number
            :sm="4"
            api-field-name="simChestIncomeSimulations"
            label="Nr of simulations"
          />
        </fields-col>
        <span class="font-related-xs absolute bottom-2 left-4 text-neutral-500">before sim: save & do CC DEV</span>
      </el-card>
      <template
        v-for="i in 5"
        :key="i"
      >
        <crud-field-selector-item
          :sm="6"
          :api-field-name="`Item${i}`"
        />
        <crud-field-number
          :sm="6"
          :api-field-name="`Quantity${i}`"
        />
        <crud-field-number
          :sm="6"
          :api-field-name="`Weight${i}`"
        />
        <crud-field-switcher
          :sm="6"
          :api-field-name="`IsPreview${i}`"
        />
      </template>
    </template>
  </crud-details-page>
</template>
