<script>
</script>

<template>
  <crud-details-page
    api="events/lottery-event"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="6">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- NameTag -->
        <crud-field-selector-client-strings
          api-field-name="NameTag"
          required
        />
        <!-- DurationHours -->
        <crud-field-number api-field-name="DurationHours" />
        <!-- RandomChestSelection -->
        <crud-field-switcher api-field-name="RandomChestSelection" />
      </fields-col>
      <fields-col :sm="6">
        <!-- AssetHomeIcon -->
        <crud-field-selector-files api-field-name="AssetHomeIcon" />
        <!-- AssetInfoPopupBackground -->
        <crud-field-selector-files api-field-name="AssetInfoPopupBackground" />
        <!-- AssetMainBackground -->
        <crud-field-selector-files api-field-name="AssetMainBackground" />
        <!-- AssetOpenAnimation -->
        <crud-field-selector-files api-field-name="AssetOpenAnimation" />
        <!-- AssetScore -->
        <crud-field-selector-files api-field-name="AssetScore" />
      </fields-col>
      <fields-col :sm="6">
        <!-- LotteryChest -->
        <crud-field-selector-chest
          api-field-name="LotteryChest"
          :init-filters="{ Type: 'Lottery' }"
        />
        <crud-field-selector-item
          api-field-name="LotteryKey"
          :init-filters="{ Type: 'ChestKey' }"
        />
        <!-- GlobalReward -->
        <CrudFieldSelectorLotteryEventGlobalReward api-field-name="GlobalReward" />
        <!-- Reward -->
        <CrudFieldSelectorLotteryEventReward api-field-name="Reward" />
        <!-- IsUnlimited -->
        <crud-field-switcher api-field-name="IsUnlimited" />
      </fields-col>
      <fields-col :sm="6">
        <fields-col
          v-for="i in 3"
          :key="i"
        >
          <fields-col>
            <crud-field-selector-offer
              :api-field-name="`Offer${i}`"
              :init-filters="{ Type: 'LotteryKey' }"
            />
          </fields-col>
        </fields-col>
        <fields-col>
          <crud-field-selector-multi-offer-groups
            api-field-name="MultiOffer"
            :init-filters="{ Type: 'LotteryKey' }"
          />
        </fields-col>
      </fields-col>
      <fields-col
        v-for="i in 10"
        :key="i"
      >
        <section-divider>Stage {{ i }}</section-divider>
        <fields-col :sm="8">
          <crud-field-number
            :api-field-name="`Stage${i}.ChestCount`"
            label="ChestCount"
          />
          <crud-field-number
            :api-field-name="`Stage${i}.WinChancePct`"
            :min="0"
            :max="100"
            label="WinChancePct"
          />
          <crud-field-number
            :api-field-name="`Stage${i}.EmptyChancePct`"
            :min="0"
            :max="100"
            label="EmptyChancePct"
          />
          <crud-field-number
            :api-field-name="`Stage${i}.GuaranteeWinRoundNo`"
            label="GuaranteeWinRoundNo"
          />
        </fields-col>
        <fields-col :sm="8">
          <!-- XpPct -->
          <crud-field-number
            :api-field-name="`Stage${i}.XpPct`"
            label="XpPct"
          />
          <!-- Energy -->
          <crud-field-number
            :api-field-name="`Stage${i}.Energy`"
            label="Energy"
          />
          <!-- Gems -->
          <crud-field-number
            :api-field-name="`Stage${i}.Gems`"
            label="Gems"
          />
        </fields-col>
        <fields-col :sm="8">
          <fields-col
            v-for="j in 3"
            :key="j"
          >
            <fields-col :sm="16">
              <crud-field-selector-item
                :api-field-name="`Stage${i}.Item${j}`"
                label="Item"
              />
            </fields-col>
            <fields-col :sm="8">
              <crud-field-number
                :api-field-name="`Stage${i}.Item${j}Amount`"
                label="Item amount"
              />
            </fields-col>
          </fields-col>
        </fields-col>
      </fields-col>
    </template>
  </crud-details-page>
</template>
