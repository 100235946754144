<script setup>
import { ref } from 'vue'
import draggable from 'vuedraggable'
import { globalProperties as app } from '!/plugins/utilities'

const lootsOrder = ref([])
function initItem(form) {
  form.simChestIncomeCount = 100
  form.simChestIncomeSimulations = 25
  lootsOrder.value = Array.from({ length: 55 }).fill(null).map((_, row) => {
    return { row: row + 1 }
  })
  return form
}
const loading = ref({})
function simChest(form, store) {
  loading.value.simChest = true
  const envApi = store?.getters['auth/envs']?.dev
  app.$axios
    .get(`${envApi}/proxy/simchest/`, {
      params: {
        chest_id: form.Versioned.Root,
        count: form.simChestIncomeCount,
        simulations: form.simChestIncomeSimulations
      }
    })
    .then(({ data }) => {
      app.$messageBox
        .confirm(data.logs, undefined, {
          confirmButtonText: 'OK',
          type: 'info',
          dangerouslyUseHTMLString: true,
          showCancelButton: false,
          icon: 'none',
          customClass: 'md:max-w-screen-md'
        })
        .catch(() => {})
    })
    .catch(app.$utils.catchError)
    .then(() => {
      loading.value.simChest = false
    })
}
function simChestIncome(form, store) {
  loading.value.simChestIncome = true
  const envApi = store?.getters['auth/envs']?.dev
  app.$axios
    .get(`${envApi}/proxy/simchestincome/`, {
      params: {
        chest_id: form.Versioned.Root,
        count: form.simChestIncomeCount,
        simulations: form.simChestIncomeSimulations
      }
    })
    .then(({ data }) => {
      app.$messageBox
        .confirm(data.logs, undefined, {
          confirmButtonText: 'OK',
          type: 'info',
          dangerouslyUseHTMLString: true,
          showCancelButton: false,
          icon: 'none',
          customClass: 'md:max-w-screen-md'
        })
        .catch(() => {})
    })
    .catch(app.$utils.catchError)
    .then(() => {
      loading.value.simChestIncome = false
    })
}
function saveItem(form) {
  const orderedData = {}

  lootsOrder.value.forEach((orderData, order) => {
    orderedData[`Item${order + 1}`] = form[`Item${orderData.row}`]
    orderedData[`Weight${order + 1}`] = form[`Weight${orderData.row}`]
    orderedData[`IsPreview${order + 1}`] = form[`IsPreview${orderData.row}`]
  })
  return { ...form, ...orderedData }
}

function onChangeOrder(markAsChanged) {
  markAsChanged(
    'lootsOrder',
    lootsOrder.value.some((element, index) => {
      return element.row !== index + 1
    })
  )
}
</script>

<template>
  <crud-details-page
    api="chests/chest"
    disable-top-margin
    :render-init-item="initItem"
    :render-saved-item="saveItem"
  >
    <template #form="{ form, markAsChanged }">
      <fields-col :sm="6">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.ChestType"
          :visible-options="['Chest']"
          required
        />
        <!-- SeasonNo -->
        <crud-field-number api-field-name="SeasonNo" />
        <!-- IsEventChest -->
        <crud-field-switcher api-field-name="IsEventChest" />
        <!-- NoItemDuplicates -->
        <crud-field-switcher api-field-name="NoItemDuplicates" />
      </fields-col>
      <fields-col :sm="4">
        <!-- RequiredLevel -->
        <crud-field-number api-field-name="RequiredLevel" />
        <!-- Price1 -->
        <crud-field-number api-field-name="Price1" />
        <!-- Price10 -->
        <crud-field-number api-field-name="Price10" />
        <!-- PriceSpecial -->
        <crud-field-number api-field-name="PriceSpecial" />
      </fields-col>
      <fields-col :sm="7">
        <!-- AssetShopPreview -->
        <crud-field-selector-files api-field-name="AssetShopPreview" />
        <!-- AssetChestAnimation -->
        <crud-field-selector-files api-field-name="AssetChestAnimation" />
        <!-- AssetBackground -->
        <crud-field-selector-files api-field-name="AssetBackground" />
        <!-- AssetBackground2 -->
        <crud-field-selector-files api-field-name="AssetBackground2" />
      </fields-col>
      <fields-col :sm="7">
        <!-- AssetSeasonPreview -->
        <crud-field-selector-files api-field-name="AssetSeasonPreview" />
        <!-- AssetChestPreview -->
        <crud-field-selector-files api-field-name="AssetChestPreview" />
        <!-- AssetSeasonBanner -->
        <crud-field-selector-files api-field-name="AssetSeasonBanner" />
      </fields-col>
      <section-divider>Loot settings</section-divider>
      <fields-col :sm="8">
        <!-- DuplicatedDrawEnergyCommon -->
        <crud-field-number api-field-name="DuplicatedDrawEnergyCommon" />
        <!-- DuplicatedDrawEnergyUncommon -->
        <crud-field-number api-field-name="DuplicatedDrawEnergyUncommon" />
        <!-- DuplicatedDrawEnergyRare -->
        <crud-field-number api-field-name="DuplicatedDrawEnergyRare" />
        <!-- DuplicatedDrawEnergyEpic -->
        <crud-field-number api-field-name="DuplicatedDrawEnergyEpic" />
        <!-- DuplicatedDrawEnergyLegendary -->
        <crud-field-number api-field-name="DuplicatedDrawEnergyLegendary" />
      </fields-col>
      <fields-col :sm="8">
        <!-- DuplicatedDrawEnergyCommon -->
        <crud-field-number api-field-name="DuplicatedDrawDuplicatePointsCommon" />
        <!-- DuplicatedDrawEnergyUncommon -->
        <crud-field-number api-field-name="DuplicatedDrawDuplicatePointsUncommon" />
        <!-- DuplicatedDrawEnergyRare -->
        <crud-field-number api-field-name="DuplicatedDrawDuplicatePointsRare" />
        <!-- DuplicatedDrawEnergyEpic -->
        <crud-field-number api-field-name="DuplicatedDrawDuplicatePointsEpic" />
        <!-- DuplicatedDrawEnergyLegendary -->
        <crud-field-number api-field-name="DuplicatedDrawDuplicatePointsLegendary" />
      </fields-col>
      <fields-col :sm="8">
        <!-- WeightCommon -->
        <crud-field-number api-field-name="WeightCommon" />
        <!-- WeightUncommon -->
        <crud-field-number api-field-name="WeightUncommon" />
        <!-- WeightRare -->
        <crud-field-number api-field-name="WeightRare" />
        <!-- WeightEpic -->
        <crud-field-number api-field-name="WeightEpic" />
        <!-- WeightLegendary -->
        <crud-field-number api-field-name="WeightLegendary" />
        <!-- WeightEnergy1 -->
        <crud-field-number api-field-name="WeightEnergy1" />
        <!-- WeightEnergy2 -->
        <crud-field-number api-field-name="WeightEnergy2" />
        <!-- WeightEnergy3 -->
        <crud-field-number api-field-name="WeightEnergy3" />
      </fields-col>
      <section-divider>Loots</section-divider>
      <el-col>
        <el-card
          shadow="never"
          class="gs-font-scaled relative mt-8 mb-10"
        >
          <fields-col>
            <crud-field-slot
              :sm="4"
              label="Sim Chest"
              empty-label
            >
              <el-button
                class="gs-height-related-xl gs-btn-outlined-primary-neutral gs-loading gs-no-icon"
                :loading="loading.simChest"
                @click="simChest(form, $store)"
              >
                Sim chest
              </el-button>
            </crud-field-slot>
            <crud-field-slot
              label="Sim Chest Income"
              empty-label
              class="flex-none"
            >
              <el-button
                class="gs-height-related-xl gs-btn-outlined-primary-neutral gs-loading gs-no-icon"
                :loading="loading.simChestIncome"
                @click="simChestIncome(form, $store)"
              >
                Sim chest income
              </el-button>
            </crud-field-slot>
            <crud-field-number
              :sm="4"
              api-field-name="simChestIncomeCount"
              label="Number of chests"
            />
            <crud-field-number
              :sm="4"
              api-field-name="simChestIncomeSimulations"
              label="Number of simulations"
            />
          </fields-col>
          <span class="font-related-xs absolute bottom-2 left-4 text-neutral-500">before sim: save & do CC DEV</span>
        </el-card>
      </el-col>
      <fields-col :sm="8">
        <!-- Energy1 -->
        <crud-field-number api-field-name="Energy1" />
      </fields-col>
      <fields-col :sm="8">
        <!-- Energy2 -->
        <crud-field-number api-field-name="Energy2" />
      </fields-col>
      <fields-col :sm="8">
        <!-- Energy3 -->
        <crud-field-number api-field-name="Energy3" />
      </fields-col>
      <fields-col>
        <fields-table>
          <template #tHeader>
            <tr>
              <th>Nr</th>
              <th>Item</th>
              <th>Weight</th>
              <th>Is preview</th>
            </tr>
          </template>
          <template #tBody>
            <draggable
              v-model="lootsOrder"
              item-key="row"
              ghost-class="gs-ghost-tr"
              tag="tbody"
              handle=".handle"
              @change="onChangeOrder(markAsChanged)"
            >
              <template #item="props">
                <tr class="bg-neutral-50">
                  <fields-table-td
                    :width="55"
                    class="relative text-center align-middle"
                    cell-class=""
                  >
                    <div class="absolute -left-1 top-0 bottom-0 flex items-center">
                      <icon-ify
                        icon="clarity:drag-handle-line"
                        class="gs-scaled-icon-xl handle cursor-move"
                      />
                    </div>
                    <div>{{ props.index + 1 }}</div>
                  </fields-table-td>
                  <fields-table-td :width="650">
                    <crud-field-selector-item
                      :api-field-name="`Item${props.element.row}`"
                      slim
                      :label="false"
                      :min-height="false"
                      disable-doc
                    />
                  </fields-table-td>
                  <fields-table-td :width="100">
                    <crud-field-number
                      :api-field-name="`Weight${props.element.row}`"
                      slim
                      :label="false"
                      :min-height="false"
                      disable-doc
                    />
                  </fields-table-td>
                  <fields-table-td :width="100">
                    <crud-field-switcher
                      :api-field-name="`IsPreview${props.element.row}`"
                      slim
                      :label="false"
                      :min-height="false"
                      disable-doc
                    />
                  </fields-table-td>
                </tr>
              </template>
            </draggable>
          </template>
        </fields-table>
      </fields-col>
    </template>
  </crud-details-page>
</template>
