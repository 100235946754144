<script setup></script>

<template>
  <crud-details-page
    api="seasons/cooking-fest"
    disable-top-margin
  >
    <template #form>
      <!-- SeasonNo -->
      <crud-field-number
        :sm="4"
        api-field-name="SeasonNo"
        required
      />
      <crud-field-date
        :sm="8"
        api-field-name="SkirmishStart"
        required
      />
      <crud-field-text
        :sm="12"
        api-field-name="Label"
      />

      <section-divider>Battle tags</section-divider>
      <template
        v-for="i in 5"
        :key="i"
      >
        <crud-field-text
          :sm="20"
          :label="`Battle tags line${i - 1} (comma separated)`"
          :api-field-name="`BattleTagsCsv${i - 1}`"
        />
      </template>
    </template>
  </crud-details-page>
</template>
