<template>
  <crud-details-page
    api="misc/dialogue"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <crud-field-text
          api-field-name="Label"
        />
      </fields-col>

      <section-divider>Lines</section-divider>
      <template
        v-for="i in 12"
        :key="i"
      >
        <fields-col :sm="24">
          <fields-col :sm="5">
            <crud-field-selector-client-strings
              :api-field-name="`Line${i}.Name`"
            />
          </fields-col>
          <fields-col :sm="5">
            <crud-field-selector-client-strings
              :api-field-name="`Line${i}.Text`"
            />
          </fields-col>
          <fields-col :sm="4">
            <crud-field-selector-files
              :api-field-name="`Line${i}.Avatar`"
              sm="8"
            />
          </fields-col>
          <fields-col :sm="4">
            <crud-field-selector-files
              :api-field-name="`Line${i}.Emoji`"
              sm="8"
            />
          </fields-col>

          <fields-col :sm="2">
            <crud-field-text
              :api-field-name="`Line${i}.Pos`"
            />
          </fields-col>
          <fields-col :sm="4">
            <crud-field-text
              :api-field-name="`Line${i}.VoiceOver`"
            />
          </fields-col>
        </fields-col>
      </template>
    </template>
  </crud-details-page>
</template>
