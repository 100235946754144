<script>
import dayjs from 'dayjs'

export default {
  data() {
    return {
      zone: [],
      utc: false,
      calendarData: {},
      zoneBg: {
        asia: 'bg-yellow-300',
        europe: 'bg-green-300',
        america: 'bg-blue-300'
      },
      groups: [
        { field: 'dates', title: 'Season \n dates' },
        { field: 'offers', title: 'Offers' },
        { field: 'events', title: 'Events' }
      ],
      pane: 0
    }
  },
  computed: {
    localCalendarData() {
      return this.renderData(this.$utils.cloneDeep(this.calendarData), false)
    },
    utcCalendarData() {
      return this.renderData(this.$utils.cloneDeep(this.calendarData), true)
    },
    renderedCalendarData() {
      return this.utc ? this.utcCalendarData : this.localCalendarData
    },
    visibleZones() {
      if (!this.zone.length) {
        return ['asia', 'europe', 'america']
      }
      return this.zone.map(zone => zone.toLowerCase())
    }
  },
  methods: {
    onLoadData(data) {
      data.rows.some((season, index) => {
        const start = dayjs(Math.min(season.america_start, season.asia_start, season.europe_start) * 1000)
        const end = dayjs(Math.max(season.america_start, season.asia_start, season.europe_start) * 1000).add(31, 'day')
        const now = dayjs()
        if (now >= start && now <= end) {
          this.pane = index
          return true
        }
        return false
      })
      return (this.calendarData = data)
    },
    renderData(data, isUtc) {
      data.type = isUtc ? 'utc' : 'local'
      data.rows.forEach((season) => {
        season.range = []
        const globalStart = dayjs(Math.min(season.america_start, season.asia_start, season.europe_start) * 1000)
        const globalEnd = dayjs(Math.max(season.america_start, season.asia_start, season.europe_start) * 1000).add(31, 'day')
        for (let day = globalStart; day <= globalEnd; day = day.add(1, 'day')) {
          season.range.push(day.format('ddd DD-MM-YYYY'))
        }
        season.rangeLength = season.range.length
        season.rangeLengthH = season.rangeLength * 24

        season.positionsH = {
          dates: {
            europe: [[{ start: 0, len: season.rangeLengthH, name: null }]],
            asia: [[{ start: 0, len: season.rangeLengthH, name: null }]],
            america: [[{ start: 0, len: season.rangeLengthH, name: null }]]
          },
          offers: {
            europe: [[{ start: 0, len: season.rangeLengthH, name: null }]],
            asia: [[{ start: 0, len: season.rangeLengthH, name: null }]],
            america: [[{ start: 0, len: season.rangeLengthH, name: null }]]
          },
          events: {
            europe: [[{ start: 0, len: season.rangeLengthH, name: null }]],
            asia: [[{ start: 0, len: season.rangeLengthH, name: null }]],
            america: [[{ start: 0, len: season.rangeLengthH, name: null }]]
          }
        }
        const zonesNames = { europe: 'Europe', asia: 'Asia', america: 'America' }
        const startCalendar = globalStart.set('hour', 0).set('minute', 0).set('second', 0)

        Object.keys(season.positionsH.dates).forEach((zone) => {
          const dateZoneStart = dayjs(season[`${zone}_start`] * 1000)
            .set('minute', 0)
            .set('second', 0)
          const time = this.utc ? dayjs(season[`${zone}_start`] * 1000).utc() : dayjs(season[`${zone}_start`] * 1000)
          const diff = dateZoneStart.diff(startCalendar, 'hour') - (this.utc ? dayjs().utcOffset() / 60 : 0)
          let offset = 0
          if (diff) {
            offset = season.positionsH.dates[zone][0][0].len = diff
          } else {
            season.positionsH.dates[zone][0].shift()
          }
          for (let day = 1; day <= 30; ++day) {
            season.positionsH.dates[zone][0].push({
              start: offset + 1,
              len: 24,
              name: `Day ${day} (${time.add(day * 24, 'hour').format('HH:mm')}) - ${zonesNames[zone]}`,
              day
            })
            offset += 24
          }
          if (offset <= season.rangeLengthH) {
            season.positionsH.dates[zone][0].push({ len: season.rangeLengthH - offset, name: null })
          }
        })

        season.offersZones = {
          europe: [],
          asia: [],
          america: []
        }

        season.eventsZones = {
          europe: [],
          asia: [],
          america: []
        }

        const previousOfferEnd = {}
        season.offers.forEach((offer) => {
          season.offersZones[offer.zone.toLowerCase()].push(offer)

          const key = offer.zone.toLowerCase() + offer.name
          if (previousOfferEnd[key] > 0 && previousOfferEnd[key] > offer.start) { // overlaps
            offer.name += '2'
          }
          if (previousOfferEnd[key] === undefined || offer.end > previousOfferEnd[key]) {
            previousOfferEnd[key] = offer.end
          }
        })

        const previousEventEnd = {}
        season.tournaments_events.forEach((event) => {
          season.eventsZones[event.zone.toLowerCase()].push(event)

          const key = event.zone.toLowerCase() + event.name
          if (previousEventEnd[key] > 0 && previousEventEnd[key] > event.start) { // overlaps
            event.name += '2'
          }
          if (previousEventEnd[key] === undefined || event.end > previousEventEnd[key]) {
            previousEventEnd[key] = event.end
          }
        })

        const fields = ['offers', 'events']
        const mapping = {
          offers: {},
          events: {}
        }
        fields.forEach((positionsHFieldName) => {
          Object.keys(season.positionsH[positionsHFieldName]).forEach((zone) => {
            season[`${positionsHFieldName}Zones`][zone].forEach((offerZone, index) => {
              if (!index) {
                season.positionsH[positionsHFieldName][zone][0].shift()
              }
              if (mapping[positionsHFieldName][zone] === undefined) {
                mapping[positionsHFieldName][zone] = {}
              }
              if (mapping[positionsHFieldName][zone][offerZone.name] === undefined) {
                mapping[positionsHFieldName][zone][offerZone.name] = Object.keys(mapping[positionsHFieldName][zone]).length
              }
              const mappingIndex = mapping[positionsHFieldName][zone][offerZone.name]
              if (season.positionsH[positionsHFieldName][zone][mappingIndex] === undefined) {
                season.positionsH[positionsHFieldName][zone][mappingIndex] = []
              }
              const dateZoneStart = dayjs(offerZone.start * 1000)
                .set('minute', 0)
                .set('second', 0)
              const dateZoneEnd = dayjs(offerZone.end * 1000)
                .set('minute', 0)
                .set('second', 0)
              const time = this.utc
                ? dayjs(offerZone.start * 1000)
                    .utc()
                    .format('HH:mm')
                : dayjs(offerZone.start * 1000).format('HH:mm')

              const startH = dateZoneStart.diff(startCalendar, 'hour') - (this.utc ? dayjs().utcOffset() / 60 : 0)
              const durationH = dateZoneEnd.diff(dateZoneStart, 'hour') - (this.utc ? dayjs().utcOffset() / 60 : 0)
              dateZoneStart.diff(startCalendar, 'hour')
              const prevPosition =
                  season.positionsH[positionsHFieldName][zone][mappingIndex][
                    season.positionsH[positionsHFieldName][zone][mappingIndex]?.length - 1
                  ]
              if (!prevPosition && startH > 1) {
                season.positionsH[positionsHFieldName][zone][mappingIndex].push({ start: 0, len: startH, name: null })
              } else if (prevPosition && prevPosition.start + prevPosition.len < startH) {
                season.positionsH[positionsHFieldName][zone][mappingIndex].push({
                  start: prevPosition.start + prevPosition.len,
                  len: startH - prevPosition.start - prevPosition.len,
                  name: null
                })
              }
              season.positionsH[positionsHFieldName][zone][mappingIndex].push({
                start: startH,
                len: durationH,
                name: `${offerZone.name} (${time}) - ${zonesNames[zone]}`
              })
            })
          })
          Object.keys(season.positionsH[positionsHFieldName]).forEach((zone) => {
            season.positionsH[positionsHFieldName][zone].forEach((calendarData) => {
              const last = calendarData[calendarData.length - 1]
              if (last.start + last.len <= season.rangeLengthH) {
                calendarData.push({
                  start: last.start + last.len,
                  len: season.rangeLengthH - last.start - last.len,
                  name: null
                })
              }
            })
          })
        })
      })
      return data
    },
    calcGroupRowspan(season, group) {
      let rowspan = 0
      this.visibleZones.forEach((zone) => {
        rowspan += season.positionsH[group.field][zone].length
      })
      return rowspan || 1
    },
    onMouseEnter(ev) {
      if (ev?.target?.clientHeight !== undefined) {
        ev.target.style.setProperty('--height-table', `${ev.target.clientHeight}px`)
      }
    },
    onMoseMove(ev) {
      if (ev.layerX !== undefined) {
        ev.target.style.setProperty('--offset', `${ev.layerX}px`)
      }
    }
  }
}
</script>

<template>
  <crud-table
    api="proxy/request"
    :versioned="false"
    :proxy-request-params="{
      uri: '/proxy/gameplay/seasoncalendar/'
    }"
    :top-actions="{
      excelExport: false,
      importExcel: false,
      addNew: false
    }"
    :static-api-params="{
      env: 'prod'
    }"
    disable-pagination
    hide-columns-selector
    hide-clear-btn
    :render-loaded-data="onLoadData"
    disable-multi-select-rows
  >
    <template #topLeft>
      <div class="flex align-middle">
        <div class="w-40">
          <crud-type-fields
            v-model="zone"
            type-value-field="select"
            full-width
            multiple
            :options="$utils.getOptionsFromArray(['Asia', 'Europe', 'America'])"
            label="Zone"
          />
        </div>
        <div>
          <crud-type-fields
            label="Time"
            class="ml-2"
          >
            <el-switch
              v-model="utc"
              inline-prompt
              active-text="&nbsp;&nbsp;UTC+00&nbsp;&nbsp;"
              inactive-text="&nbsp;&nbsp;Local&nbsp;&nbsp;"
              inactive-color="#13ce66"
              active-color="#13ce66"
              class="-translate-y-1"
            />
          </crud-type-fields>
        </div>
      </div>
    </template>
    <template #table="{ data, loading, height }">
      <div
        v-if="data?.rows?.length"
        class="px-4 pt-4"
        :class="{ 'opacity-30': loading }"
      >
        <el-tabs
          v-model="pane"
          class="w-full"
        >
          <el-tab-pane
            v-for="(season, seasonIndex) in renderedCalendarData.rows"
            :key="seasonIndex"
            :label="`Season ${season.season_id}`"
            :name="seasonIndex"
            lazy
          >
            <transition
              name="el-fade-in"
              appear
            >
              <el-scrollbar
                v-if="pane === seasonIndex"
                class="w-full"
                :max-height="height - 90"
              >
                <table
                  class="gs-font-scaled calendar border-collapse mb-4 table-fixed whitespace-nowrap"
                  @mouseenter="onMouseEnter"
                >
                  <colgroup>
                    <col>
                    <col
                      v-for="col in season.rangeLength"
                      :key="col"
                      span="24"
                      :class="{ 'bg-slate-100': !!(col % 2), 'bg-zinc-200': !(col % 2) }"
                    >
                  </colgroup>
                  <thead class="sticky top-0 z-20">
                    <tr>
                      <td
                        rowspan="2"
                        class="sticky left-0 bg-white pr-2 text-center text-cyan-900 z-50"
                      >
                        Season <br>
                        {{ season.season_id }}
                      </td>
                      <th
                        v-for="(day, dayIndex) in season.range"
                        :key="dayIndex"
                        :colspan="24"
                        class="sticky left-0 h-20 whitespace-pre bg-sky-100 align-middle text-cyan-700 z-30"
                      >
                        <div class="w-full h-full p-2 border-r border-sky-200 flex justify-center items-center">
                          {{ day }}
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <td
                        v-for="td in season.rangeLengthH"
                        :key="td"
                        class="h-4 w-3 gs-event-day"
                        :class="{ 'bg-slate-100': !!(Math.ceil(td / 24) % 2), 'bg-zinc-200': !(Math.ceil(td / 24) % 2) }"
                      >
                        <div class="font-related-xss w-full flex items-center justify-center text-center text-gray-500">
                          <div class="leading-0">
                            {{ (td - 1) % 24 }}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="(group, grIndex) in groups"
                      :key="grIndex"
                    >
                      <tr>
                        <th class="sticky left-0 h-2 bg-white" />
                        <td
                          :colspan="season.rangeLengthH - 1"
                          class="h-2 bg-white"
                        />
                      </tr>
                      <template
                        v-for="(zoneV, indexZone) in visibleZones"
                        :key="`${seasonIndex}_dates_${zoneV}`"
                      >
                        <tr
                          v-for="(row, indexRow) in season.positionsH[group.field][zoneV]"
                          :key="`${seasonIndex}_position_${zoneV}_${indexRow}`"
                        >
                          <th
                            v-if="!indexZone && !indexRow"
                            :rowspan="calcGroupRowspan(season, group)"
                            class="sticky left-0 h-20 whitespace-pre border border-sky-200 bg-sky-100 p-2 align-middle text-cyan-700 z-40"
                          >
                            {{ group.title }}
                          </th>
                          <template
                            v-for="(position, indexPos) in row"
                            :key="`${seasonIndex}_position_${zoneV}_${indexRow}_${indexPos}`"
                          >
                            <template v-if="position.name === null">
                              <td
                                v-for="emptyTdIndex in position.len"
                                :key="`${indexPos}_${emptyTdIndex}`"
                                class="h-7 pl-4 gs-no-event"
                              />
                            </template>
                            <td
                              v-else
                              :colspan="position.len"
                              class="h-7 px-2 text-center gs-event"
                              :class="[zoneBg[zoneV]]"
                              @mousemove="onMoseMove"
                            >
                              {{ position.name }}
                            </td>
                          </template>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </el-scrollbar>
            </transition>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div v-else-if="loading">
        loading...
      </div>
      <div v-else-if="!loading && data?.rows !== undefined">
        no data
      </div>
      <div />
    </template>
  </crud-table>
</template>

<style lang="postcss" scoped>
:deep(table) {
  --height-table: 0px;
  position: relative;
  td {
    border: 1px solid white;
  }
  td.gs-event {
    --offset: 8px;
  }
  td:not(.gs-event):hover::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: var(--height-table);
    width: 1px;
    border-right: 1px dashed orange;
    z-index: 40;
  }
  td.gs-no-event::after {
    transform: translateX(-8px);
  }
  td.gs-event-day::after {
    transform: translateX(8px);
  }
  td.gs-event:hover::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: var(--offset);
    height: var(--height-table);
    width: 1px;
    border-right: 1px dashed orange;
    z-index: 40;
  }
}
</style>
