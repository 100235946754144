<template>
  <crud-details-page
    api="misc/idle-loot"
  >
    <template #form>
      <fields-col :sm="10">
        <crud-field-text
          api-field-name="Label"
        />
      </fields-col>
      <fields-col :sm="2">
        <crud-field-number
          api-field-name="Quality"
        />
      </fields-col>
      <fields-col :sm="6">
        <crud-field-date-time
          api-field-name="AvailableFrom"
        />
      </fields-col>
      <fields-col :sm="6">
        <crud-field-date-time
          api-field-name="AvailableTo"
        />
      </fields-col>

      <section-divider>Loot</section-divider>
      <template
        v-for="i in 15"
        :key="i"
      >
        <fields-col :sm="24">
          <fields-col :sm="10">
            <crud-field-selector-item
              :api-field-name="`Item${i}`"
            />
          </fields-col>
          <fields-col :sm="4">
            <crud-field-number
              :api-field-name="`Weight${i}`"
            />
          </fields-col>
        </fields-col>
      </template>
    </template>
  </crud-details-page>
</template>
